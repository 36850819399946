import React, { useEffect } from "react";
import {
  RecruitmentSteps,
  ScreenFlow,
  PreScreen,
  Loading,
} from "../../components";
import {
  saveButton,
  greenDot,
  connectMyEmr,
  Manual,
  uploadDocument,
  i_connect_emr,
  i_upload,
  i_enter,
} from "../theme/icons";
import { getTitle, countries } from "../../helpers";
import { AcknowledgementModal } from ".";
import { environment, Recaptcha_site_key } from "../../constants";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";

const Participant = (info) => {
  const {
    disabledTrials,
    onCloseModal,
    showAcknowledgement,
    loadingForModal,
    type,
    trial,
    studyId,
    firstname,
    lastname,
    city,
    country,
    zipcode,
    email,
    showEmailError,
    showFirstNameError,
    showLastNameError,
    gender,
    patient_id,
    dob,
    phone,
    smokingstatus,
    primarylanguage,
    currentmedication,
    healthcondition,
    familyhistory,
    onTextInputChange,
    onRaceCheckboxChange,
    signUp,
    onAcknowledge,
    hideSteps,
    showPrescreening,
    showRegisterOptions,
    isLoading,
    questions,
    optionChange,
    onEnroll,
    show_patient_id,
    navigateOption,
    onRecaptchaChange,
    recaptcha_response,
  } = info;
  const flowarray = [
    { title: "Home", link: "/" },
    { title: "Find Trials", link: "/#find_trial" },
    // {
    //   title: "Search Results",
    //   link: `/results?q=${condition}&in=${country}`,
    // },
    // { title: "Trial Details", link: `/trialdetails/${studyId}/${type}` },
    // { title: "Enroll", link: `/enroll/${studyId}/${type}` },
  ];

  const registerOptions = [
    {
      id: 1,
      title: "Connect My EMR",
      icon: i_connect_emr,
    },
    {
      id: 2,
      title: "Upload Document",
      icon: i_upload,
    },
    {
      id: 3,
      title: "Enter Manually",
      icon: i_enter,
    },
  ];

  // useEffect(()=>{
  //   window.scrollTo(0,0)
  // })

  return (
    <div>
      {hideSteps === true ? null : <RecruitmentSteps step={"4. Enroll"} />}
      <div className="container px-0">
        {isLoading === false ? (
          <Loading />
        ) : (
          <div className="trialdetails row">
            <div
              className={`col-sm-12 ${
                (environment === "PROD" && studyId === "STU01009") ||
                (environment === "DEMO_ETRIALS" && studyId === "STU0001002")
                  ? "col-lg-12 col-md-12 px-1"
                  : "col-lg-9 col-md-9 px-1"
              }`}
            >
              {hideSteps === true ? null : (
                <div className="d-flex align-items-start justify-content-between">
                  <ScreenFlow flow={flowarray} />
                  {/* <img src={saveButton} alt="save icon"></img> */}
                </div>
              )}
              {/* {(environment === "PROD" && studyId === "STU01009") ||
              (environment === "DEMO_ETRIALS" && studyId === "STU0001002") ? null : ( */}
                <div className="px-1">
                  {studyId.startsWith("STU") === true ? (
                    <div>
                      <h3 className="updated_date">
                        Last updated on{" "}
                        {moment(trial && trial.sys_updated_on).format(
                          "MMMM Do YYYY"
                        )}
                      </h3>
                    </div>
                  ) : null}
                  <div className="mt-1 d-flex align-items-start justify-content-between">
                    <div>
                      {/* <img className="mx-2" src={greenDot} alt="greendot"></img> */}
                      {studyId && studyId.startsWith("STU") === true ? (
                        <span>
                          {trial &&
                          trial.study_phase &&
                          trial.study_phase.length > 0
                            ? trial.study_phase
                            : trial &&
                              trial.nct_number &&
                              trial.nct_number.length > 0
                            ? trial.nct_number
                            : "None"}
                        </span>
                      ) : (
                        <span>
                          {trial && trial.phase && trial.phase.length > 0
                            ? trial.phase
                            : "None"}
                        </span>
                      )}
                      <span className="mx-1">{"|"}</span>
                      {studyId && studyId.startsWith("STU") === true ? (
                        <span className="mx-1">
                          {trial &&
                          trial.conditions &&
                          trial.conditions.length > 0
                            ? trial.conditions
                            : trial &&
                              trial.study_name &&
                              trial.study_name.length > 0
                            ? trial.study_name
                            : "None"}
                        </span>
                      ) : trial &&
                        trial.conditions &&
                        trial.conditions.length > 0 ? (
                        trial.conditions.length < 2 ? (
                          trial.conditions.map((disease, i) => (
                            <span key={i} className="">
                              {disease}
                            </span>
                          ))
                        ) : (
                          <span>
                            {/* {`${JSON.parse(trial.conditions)[0]}, ${
                            trial.conditions.length - 1
                          } more ...`} */}
                          </span>
                        )
                      ) : (
                        <span className="">{"None"}</span>
                      )}
                    </div>
                  </div>
                </div>
              {/* )} */}
              <div className="mt-2 enrollmentsheet_header p-1">
                {/* {(environment === "PROD" && studyId === "STU01009") ||
                (environment === "DEMO_ETRIALS" && studyId === "STU0001002") ? null : ( */}
                  <div>
                    {getTitle(type, trial)}
                    <h3 className="py-4">
                      Clinical Study Identifier
                      <h4 className="mb-0 csi_text">{studyId}</h4>
                    </h3>
                    <div className="row">
                      {/* <h3 style={{ fontFamily: 'NunitoRegular', fontWeight: '600'}} className="my-0">Enrollment Sheet</h3>
                        <span style={{ fontFamily: 'NunitoRegular', fontWeight: '600'}} className="mx-3 mb-2">-</span> */}
                      {showPrescreening === true && isLoading === true ? (
                        <h3
                          style={{
                            fontFamily: "NunitoRegular",
                            fontWeight: "600",
                          }}
                          className="mb-0 text-center col-xs-6"
                        >
                          Please fill the pre-screening questions
                        </h3>
                      ) : (
                        isLoading === true &&
                        (showRegisterOptions === true ? null : (
                          <h3
                            style={{
                              fontFamily: "NunitoRegular",
                              fontWeight: "600",
                            }}
                            className="mb-0 text-center col-xs-6"
                          >
                            Please fill in the form below and press the button
                            at the bottom of the page to complete Sign Up
                          </h3>
                        ))
                      )}
                    </div>
                  </div>
                {/* )} */}
                {/* <div className="my-4 d-flex align-items-center justify-content-between">
                <h2>Do you have an account already?</h2>
                <div className="col-sm-4 pr-0" style={{ borderRadius: "3px" }}>
                  <a
                    href="#"
                    className="signin d-flex align-items-center justify-content-center border rounded btn btn-lg"
                  >
                    SIGN IN
                  </a>
                </div>
              </div> */}
                <div className="my-0 d-flex align-items-center justify-content-around m-0 p-0">
                  {/* {showRegisterOptions === true ? null : 
                  <div className="col-sm-1 col-md-1 col-lg-1 col-xs-1 text-left pl-0 d-none d-lg-block ">
                    <hr className="d-none d-lg-block" />
                  </div>} */}

                  {/* <div className="col-sm-1 col-md-1 col-lg-1 col-xs-1 text-center pr-0 d-none d-lg-block ">
                    <hr className="d-none d-lg-block" />
                  </div> */}
                </div>
              </div>
              {showPrescreening === true && isLoading === true ? (
                <div className="my-3">
                  <PreScreen
                    questions={questions}
                    optionChange={optionChange}
                    onEnroll={onEnroll}
                  />
                </div>
              ) : showPrescreening === false && isLoading === false ? (
                <Loading />
              ) : showRegisterOptions === true ? (
                <div className="my-1">
                  <h6>Please select from one of options below</h6>
                  <div className="d-flex align-items-center justify-content-between">
                    {registerOptions.map((option) => (
                      <div
                        onClick={() => navigateOption(option.title)}
                        key={option.id}
                        style={{ backgroundColor: "#034EA2" }}
                        className="register_option p-5 m-2"
                      >
                        <div className="d-flex align-items-center justify-content-center flex-column">
                          <img
                            src={option.icon}
                            className="mr-0 my-2"
                            alt="register_icon"
                          ></img>
                          <h4 className="register_option_title">
                            {option.title}
                          </h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="row my-3">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2">
                          {"Country"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          className="form-control py-0 pl-1"
                          name={"country"}
                          onChange={onTextInputChange}
                          value={country}
                          placeholder={"Select"}
                        >
                          {countries.map((nation, i) => (
                            <option key={i} value={nation}>
                              {nation}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* <div className="textinput form-group">
                        <p>{"Country*"}</p>
                        <select
                          onChange={onTextInputChange}
                          className="p-2 form-control"
                          placeholder={"Select"}
                          name={"country"}
                          value={country}
                        >
                          {countries.map((nation, i) => (
                            <option key={i} value={nation}>
                              {nation}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2 ml-1">
                          {"City"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          type="text"
                          className="form-control py-0"
                          name={"city"}
                          onChange={onTextInputChange}
                          value={city}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2">
                          {"Zipcode"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          type="number"
                          className="p-2 form-control"
                          name={"zipcode"}
                          onChange={onTextInputChange}
                          value={zipcode}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2">
                          {"First Name"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          type="text"
                          className="p-2 form-control"
                          name={"firstname"}
                          onChange={onTextInputChange}
                          value={firstname}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2">
                          {"Last Name"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          type="text"
                          className="p-2 form-control"
                          name={"lastname"}
                          onChange={onTextInputChange}
                          value={lastname}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2">
                          {"Email"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          type="email"
                          className="p-2 form-control"
                          name={"email"}
                          onChange={onTextInputChange}
                          value={email}
                          placeholder={"Enter"}
                        ></input>
                        {showEmailError ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: 11,
                              fontWeight: "bold",
                            }}
                            className="mb-0 mt-3"
                          >
                            Please enter valid email
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2 ml-1">
                          {"Gender"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          onChange={onTextInputChange}
                          className="pl-2 form-control py-0"
                          placeholder={"Select"}
                          name={"gender"}
                          value={gender}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "Male", value: "male" },
                            { title: "Female", value: "female" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2">
                          {"Date of Birth"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          type="date"
                          className="p-2 form-control"
                          name={"dob"}
                          onChange={onTextInputChange}
                          value={dob}
                          placeholder={"DD/MM/YYYY"}
                        ></input>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input form-group"
                      >
                        <p className="mb-0 pl-2">
                          {"Primary Contact Number"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <input
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          type="number"
                          className="p-2 form-control"
                          name={"phone"}
                          onChange={onTextInputChange}
                          value={phone}
                          placeholder={"Enter"}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="row my-3">
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input mt-2 form-group"
                      >
                        <p className="mb-0 pl-3">
                          {"Smoking Status"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          onChange={onTextInputChange}
                          className="form-control py-0"
                          placeholder={"Select"}
                          name={"smokingstatus"}
                          value={smokingstatus}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "Current", value: "Current" },
                            { title: "Ex", value: "Ex" },
                            { title: "Never", value: "Never" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                      <div
                        style={{ height: 55 }}
                        className="search_input mt-2 form-group"
                      >
                        <p className="mb-0 pl-3">
                          {"Primary Language"}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <select
                          style={{
                            border: "none",
                            outline: "none",
                            height: 20,
                          }}
                          onChange={onTextInputChange}
                          className="form-control py-0"
                          placeholder={"Select"}
                          name={"primarylanguage"}
                          value={primarylanguage}
                        >
                          {[
                            { title: "Please Select", value: " " },
                            { title: "English", value: "en" },
                            { title: "Spanish", value: "sp" },
                            { title: "Vietnamese", value: "vt" },
                          ].map((selectoption, i) => (
                            <option key={i} value={selectoption.value}>
                              {selectoption.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {show_patient_id === "true" ? (
                      <div className="col-sm-12 col-md-12 col-xs-12 col-lg-4 px-2">
                        <div
                          style={{ height: 55 }}
                          className="search_input mt-2 form-group"
                        >
                          <p className="mb-0 pl-3">{"Patient ID"}</p>
                          <input
                            style={{
                              border: "none",
                              outline: "none",
                              height: 20,
                            }}
                            type="text"
                            onChange={onTextInputChange}
                            className="p-2 form-control"
                            placeholder={"Enter Patient ID"}
                            name={"patient_id"}
                            value={patient_id}
                          ></input>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="textinput my-3">
                    <p className="mb-0">
                      How do you identify your race or ethnicity?{" "}
                      <span style={{ color: "red", fontSize: 13 }}>*</span>
                    </p>
                    {[
                      {
                        title: "American Indian or Alaskan Native",
                        value: "American Indian or Alaskan Native",
                      },
                      {
                        title: "Asian or Pacific Islander",
                        value: "Asian or Pacific Islander",
                      },
                      {
                        title: "Black or African American",
                        value: "Black or African American",
                      },
                      {
                        title: "Hispanic or Latino",
                        value: "Hispanic or Latino",
                      },
                      {
                        title: "White or Caucasian",
                        value: "White or Caucasian",
                      },
                      {
                        title: "(Mixed Race) American Indian or Alaskan Native",
                        value: "(Mixed Race) American Indian or Alaskan Native",
                      },
                      {
                        title: "(Mixed Race) Asian or Pacific Islander",
                        value: "(Mixed Race) Asian or Pacific Islander",
                      },
                      {
                        title: "(Mixed Race) Black or African American",
                        value: "(Mixed Race) Black or African American",
                      },
                      {
                        title: "(Mixed Race) Hispanic or Latino",
                        value: "(Mixed Race) Hispanic or Latino",
                      },
                      {
                        title: "(Mixed Race) White or Caucasian",
                        value: "(Mixed Race) White or Caucasian",
                      },
                    ].map((raceItem, i) => (
                      <div key={i} className="promo_answer">
                        <input
                          style={{ height: "auto", width: "auto" }}
                          className="mx-1 mt-2"
                          type={"checkbox"}
                          onChange={(e) =>
                            onRaceCheckboxChange(e, raceItem.title)
                          }
                          name={raceItem.value}
                          value={raceItem.value}
                        />
                        <span className="mx-2">{raceItem.title}</span>
                      </div>
                    ))}
                  </div>
                  <div className="">
                    <div className="pl-0">
                      <div
                        style={{ height: 120 }}
                        className="search_input form-group"
                      >
                        <p className="pl-2 mb-0">{"Current Medication"}</p>
                        <textarea
                          className="col-12 px-2 py-0 form-control"
                          type="text"
                          name={"currentmedication"}
                          onChange={onTextInputChange}
                          style={{
                            border: "none",
                            outline: "none",
                            height: 100,
                          }}
                          value={currentmedication}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                    <div className="pl-0">
                      <div
                        style={{ height: 120 }}
                        className="search_input form-group"
                      >
                        <p className="pl-2 mb-0">
                          {"Health Condition "}
                          <span style={{ color: "red", fontSize: 13 }}>*</span>
                        </p>
                        <textarea
                          className="col-12 p-2 form-control"
                          type="text"
                          name={"healthcondition"}
                          onChange={onTextInputChange}
                          style={{
                            border: "none",
                            outline: "none",
                            height: 100,
                          }}
                          value={healthcondition}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                    <div className="pl-0">
                      <div
                        style={{ height: 120 }}
                        className="search_input form-group"
                      >
                        <p className="pl-2 mb-0">{"Relevant Family History"}</p>
                        <textarea
                          className="col-12 p-2 form-control"
                          type="text"
                          name={"familyhistory"}
                          style={{
                            border: "none",
                            outline: "none",
                            height: 100,
                          }}
                          onChange={onTextInputChange}
                          value={familyhistory}
                          placeholder={"Enter"}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ReCAPTCHA
                      sitekey={Recaptcha_site_key}
                      onChange={onRecaptchaChange}
                      // ref={ref}
                    />
                  </div>
                  {recaptcha_response === "" ? (
                    <p style={{ color: "red", fontSize: 13 }}>
                      Please fill recaptcha
                    </p>
                  ) : null}
                  <div className="my-2 d-flex align-items-center justify-content-end">
                    <AcknowledgementModal
                      disabledTrials={disabledTrials}
                      loadingForModal={loadingForModal}
                      onCloseModal={onCloseModal}
                      signUp={signUp}
                      onAcknowledge={onAcknowledge}
                      showAcknowledgement={showAcknowledgement}
                    />
                  </div>
                </div>
              )}
            </div>
            {(environment === "PROD" && studyId === "STU01009") ||
            (environment === "DEMO_ETRIALS" && studyId === "STU0001002") ? null : (
              <div
                style={{ backgroundColor: "#F9F9F9" }}
                className="closestlocation h-75 col-sm-12 col-md-3 col-lg-3 p-4 mt-5 text-left"
              >
                <h2>YOU'RE ENROLLING TRIAL AT</h2>
                <h3>{trial && trial.site_name}</h3>
                <h2>
                  {trial &&
                    trial.street_address + trial &&
                    trial.city + trial &&
                    trial.state}
                </h2>
                {/* <div className="notification text-left">
              <h2>GET NOTIFIED</h2>
              <br></br>
              <h2>
                Sign up to receive email notification when clinical trials are
                posted in the medical category of interest to you.{" "}
              </h2>
              <div style={{ border: "1px solid #E57D24", borderRadius: "3px" }}>
                <a
                  href="#"
                  className="turn-on-notification d-flex align-items-center justify-content-center border rounded btn btn-lg"
                >
                  TURN ON NOTIFICATION
                </a>
              </div>
            </div> */}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Participant;
