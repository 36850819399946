import React, { useEffect } from "react";
import {
  Loading,
} from ".";
import { saveButton, greenDot, connectMyEmr, Manual, uploadDocument } from "../features/theme/icons";
import { environment } from "../constants";
import { getTrialId } from "../helpers";
import { log } from "../helpers/helper";

class Concierge extends React.Component {
  constructor(props){
    super();
    this.state={
      study:"",
      pt_number:"",
      fname:"",
      trial: "",
      current_url : ""
    }
  }

  componentDidMount(){
    this.setState({current_url : window.location.href})
    let search_terms = this.props.location.search;
    log(search_terms.split("&"),'params')
    let study = search_terms.split("&")[0].split("=")[1]
    let pt_number = search_terms.split("&")[1].split("=")[1]
    let fname = search_terms.split("&")[2].split("=")[1]

    log(study, pt_number, fname, 'details');
    this.setState({
      study: study,
      pt_number : pt_number,
      fname : fname
    },()=>{
      getTrialId(this.state.study)
      .then((gotStudy) => {
        log(gotStudy,'got study')
        this.setState({
          trial : gotStudy && gotStudy.trial
        },()=>{
          if ((this.state.trial &&
            this.state.trial.account_sid &&
            this.state.trial.account_sid !== undefined) &&
            (this.state.trial &&
            this.state.trial.account_sid &&
            this.state.trial.account_sid.length > 0)
          ) {
            window.localStorage.setItem("study_nct", this.state.trial && this.state.trial.nct_number)
                  window.localStorage.setItem("study_sid", this.state.trial && this.state.trial.study_sys_id)
            window.localStorage.setItem(
              "acc_sid",
              this.state.trial && this.state.trial.account_sid
            );
            window.localStorage.setItem(
              "flow_id",
              this.state.trial && this.state.trial.flex_flow_id
            );
            window.localStorage.setItem("chat_status", "started");
              window.localStorage.setItem("prospectID", this.state.pt_number);
              window.localStorage.setItem("f_name", this.state.fname);
              let c_status = window.localStorage.getItem("chat_status")
              if((c_status !== undefined) || (c_status !== "") || (c_status !== " ") || (c_status !== null)){
                  window.location.href = "/nurse"
              }
          }
        })
      }).catch(errStudyDetails=>{
        log(errStudyDetails,'err while fetching study details')
      })
    })
  }

render(){
    return (
        <div className="my-5 py-5 container">
            <h6>Nurse Concierge</h6>
        </div>
      );
}
  
};

export default Concierge;
