import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import PromoTrials from "../../features/promo trials";
import { HomeController } from "../home";
import { ResultsController } from "../scope";
import { ReviewTrialController } from "../review trials";
import {PtEnrollScreen} from "../review trials/trialdetails"
import { EnrollmentController, ThankYou } from "../enroll";
import { DataNotFound, Footer, Header, Loading } from "../../components";
import { Blogs } from "../blogs";
import { Domain, environment } from "../../constants";
import Preview from "../preview trials/preview";
import Kira from "../../components/kira";
import Mosaic from "../../components/mosaic";
import Vlu from "../../components/vlu";
import EMR from "../../components/Emr";
import EHR from "../../components/Ehr";
import Conference from "../../components/Conference";
import ConnectEMR from "../../components/ConnectToEMR";
import ConferenceCheck from "../../components/ConferenceCheck";
import Records from "../../components/Records";
import Agent from "../../components/Agent";
import DocumentUpload from "../../components/DocumentUpload";
import DocumentEnrollment from "../enroll/DocumentEnrollmentController";
import Navigate from "../../components/Navigate"
import Concierge from "../../components/Concierge";
import Nurse from "../../components/Nurse";
import { log } from "../../helpers/helper";

//import Test from "../../components/Test";

class Navigation extends React.Component {
  componentDidMount(){
    //log(this.props,'url')
  }
  render() {
    return (
      <div>
        {this.props.location && this.props.location.search && this.props.location.search.indexOf("embed=true") !== -1 ? <Header showHeader={false} /> : <Header />}
        <Switch>
          <Route exact path="/" component={HomeController} />
          <Route exact path="/signup">
            <Redirect to={environment === "PROD" ? "/enroll/STU01009" : "/enroll/STU0001002"}></Redirect>
          </Route>
          <Route exact path="/participate">
            <Redirect to={environment === "PROD" ? "/enroll/STU01009" : "/enroll/STU0001002"}></Redirect>
          </Route>
          <Route exact path={`/results/`} component={ResultsController} />
          <Route exact path="/demo" component={Navigate} />
          <Route exact path="/KIRA" component={Kira} />
          <Route exact path="/kira" component={Kira} />
          <Route exact path="/mosaic" component={Mosaic} />
          <Route exact path="/MOSAIC" component={Mosaic} />
          <Route exact path="/vlu" component={Vlu} />
          <Route exact path="/VLU" component={Vlu} />
          <Route exact path="/concierge" component={Concierge} />
          <Route exact path="/nurse" component={Nurse} />
          <Route exact path="/medical-records/:prospect/:city" component={EMR} />
          <Route exact path="/emr" component={EHR} />
          <Route exact path="/agent" component={Agent} />
          <Route exact path="/upload-document/:studyId" component={DocumentUpload} />
          <Route exact path ="/upload-enroll/:studyId" component={DocumentEnrollment} />
          <Route exact path="/connect-to-emr/:randomID/:study_sys_id/:domain_id/:study_number" component={ConnectEMR} />
          <Route exact path="/health-records/:randomID/:study_sys_id/:domain_id/:study_number" component={Records} />
          {/* <Route exact path="/test" component={Test} /> */}
          <Route
            exact
            path={"/study/:studyId"}
            component={ReviewTrialController}
          />
          <Route exact path={`/thankyou/:pt`} component={ThankYou} />
          <Route exact path={`/upcoming-research/`} component={PtEnrollScreen} />
          <Route exact path={`/loading/`} component={Loading} />
          <Route path={`/enroll/:studyId`} component={EnrollmentController} />
          {/* <Route exact path={"/study/:nctid"} component={PromoTrials} /> */}
          <Route exact path={"/preview/:studyId"} component={Preview} />
          <Route exact path={"/conference/:patient/:appointmentId"} component={Conference} />
          <Route exact path={"/conference-check/:patient/:appointmentId"} component={ConferenceCheck} />
          {/* <Route exact path={"/blog/:blogid"} component={Blogs} />
          <Route exact path={"/blog"} component={Blogs} /> */}
          <Route component={DataNotFound} />
        </Switch>
        {this.props.location && this.props.location.search && this.props.location.search.indexOf("embed=true") !== -1 ? <Footer showFooter={false} /> : <Footer />}
      </div>
    );
  }
}

export default withRouter(Navigation);
