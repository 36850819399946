import React, { useState, useEffect } from "react";
import "../../App.css";
import {
  greenDot,
  step3,
  step4,
  step5,
  arrowRight,
  Iphone,
  logo,
  PlayStore,
  AppStore,
  QR,
  shareMedIcon,
  EligibilityScreening,
ConsentedProcess,
start_the_trial,
i_pt_phone,
} from "../theme/icons";
import { getPhase, getTitle, getConditions } from "../../helpers";
import { RecruitmentSteps, PopularSearch } from "../../components";
import { log } from "../../helpers/helper";
import { useParams  } from "react-router-dom";

const backToSearch = () => {
  window.location.href = "/";
};

const nextsteps = [
  {
    stepIcon: EligibilityScreening,
    stepTitle: "3. Eligibility Screening",
    stepDescription:
      "Our clinical research coordinators will review your application carefully and contact you with next steps in email and/or call.",
  },
  {
    stepIcon: ConsentedProcess,
    stepTitle: "4. Consented Process",
    stepDescription:
      "Clinical research coordinator will go over the trial details and you will have time to consult your health care providers and loved one before taking the decision.",
  },
  {
    stepIcon: start_the_trial,
    stepTitle: "5. Start the Trial",
    stepDescription:
      "After signing the consent you will be assigned to a professional to answer your questions on the app through out the journey. Trial period will vary based on study.",
  },
];

const NextStep = ({ stepIcon, stepTitle, stepDescription }) => {
  return (
    // <div className="nextstep col-sm-12 col-md-12 col-lg-6">
    //   <img className="" src={stepIcon} alt="stepicons"></img>
    //   <h2 className="mt-2">{stepTitle}</h2>
    //   <p className="mt-1">{stepDescription}</p>
    // </div>
    <div className="col-lg-6 px-0 my-2">
       <div className="row px-0">
          <img className="" src={stepIcon} alt="step_icon"></img>
          <div className="col-lg-6 mt-1">
              <h6 style={{fontStyle: "normal",fontWeight:  'bold', fontSize:  18, fontFamily :  'NunitoBold',letterSpacing : 0,color: '#231F20',opacity: 1}} className="mt-2">{stepTitle}</h6>
              <p style={{  fontStyle: "normal",fontWeight:  600, fontSize:  16, fontFamily :  'NunitoRegular',letterSpacing : 0,color: '#66676B',opacity: 1}} className="mb-0">{stepDescription}</p>
          </div>
       </div>
    </div>
  );
};

const navigateEmr = (id, city, dob, gender, nctNumber) => {
  window.localStorage.setItem("nctId", nctNumber);
  setTimeout(()=>{
    window.location.href = `/medical-records/${id}/${city}?dob=${dob}&gender=${gender}`;
  },1500)
};

const RenderNextSteps = ({
  type,
  pt,
  trial,
  patient_id,
  patientSysId,
  studyId,
  prospect_city,
  nextStep,
  setShowNextStep,
  prospect_gender,
  prospect_dob,
  nctNumber,
  preconnect
}) => {
  return (
    <div className="container-fluid px-0 pt-4">
      <div className="container px-1">
        {/* completed steps section */}
        <div className="row px-0 mx-0">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="completed_steps">
              {/* <img className="mx-2" src={greenDot} alt="greendot"></img>
              {studyId && studyId.startsWith("STU") === true ? (
                <span>
                  {trial && trial.study_phase && trial.study_phase.length > 0
                    ? trial.study_phase
                    : trial && trial.nct_number && trial.nct_number.length > 0
                    ? trial.nct_number
                    : "None"}
                </span>
              ) : (
                <span>
                  {trial && trial.phase && trial.phase.length > 0
                    ? trial.phase
                    : "None"}
                </span>
              )}
              <span className="mx-1">{"|"}</span>
              {studyId && studyId.startsWith("STU") === true ? (
                <span className="mx-1">
                  {trial && trial.conditions && trial.conditions.length > 0
                    ? trial.conditions
                    : trial && trial.study_name && trial.study_name.length > 0
                    ? trial.study_name
                    : "None"}
                </span>
              ) : trial && trial.conditions && trial.conditions.length > 0 ? (
                trial.conditions.length < 2 ? (
                  trial.conditions.map((disease, i) => (
                    <span key={i} className="">
                      {disease}
                    </span>
                  ))
                ) : (
                  <span>{`${JSON.parse(trial.conditions)[0]}, ${
                    trial.conditions.length - 1
                  } more ...`}</span>
                )
              ) : (
                <span className="">{"None"}</span>
              )} */}
              <div className="d-flex align-items-center justify-content-center">
                <h3 style={{color : '#231F20'}}
                  className="py-3 tx_text mt-3"
                >
                  Thank you for signing up!
                </h3>
              </div>
              {getTitle(type, trial)}
              {nextStep === true ? (
                <div
                  style={{ 
                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                    border: '1px solid #E6E7E8',
                    opacity: 1}}
                  className="my-3 pb-2"
                >
                  <div
                    style={{ backgroundColor: "#034EA2" }}
                    className="py-3 d-flex align-items-center justify-content-center"
                  >
                    <h2
                      style={{
                        fontFamily: "NunitoRegular",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: 18,
                        lineHeight: "22px",
                        color: "#FFFFFF",
                      }}
                    >
                      Your Patient ID : {pt}
                    </h2>
                  </div>

                  {preconnect === true ? null : 
                  <div>
                      <div className="row col-lg-12 px-5 mx-0 my-3">
                          {/* <div className="px-0">
                            <img
                              style={{ height: 77, width: 77 }}
                              src={shareMedIcon}
                              alt="share icon"
                            ></img>
                          </div>
                          <div className="px-0 share_records_content">
                            <h5 style={{ color: "black" }} className="mb-0 mt-4 mx-3">
                              Share Your Medical Records
                            </h5>
                          </div> */}
                              <div className="">
                                <p
                                  style={{ color: "black", fontSize: 18 }}
                                  className="mb-0 my-2"
                                >
                                  Please share your medical records for the study pre
                                  screening process to avoid delays. Your privacy and
                                  protecting your data is an utmost priority for us. Your
                                  medical records will be reviewed by the study
                                  coordinator and you will be notified on every access to
                                  your medical records. Rest assured, you are in safe
                                  hands. Please press on the Next button to continue.
                                </p>
                                <br></br>
                                <p
                                  style={{ color: "black", fontSize: 18 }}
                                  className="mb-0 my-2"
                                >
                                  <span style={{ fontWeight: "bold", fontSize: 15 }}>
                                    NOTE:
                                  </span>{" "}
                                  You may want to keep the User ID and Password shared by
                                  your primary care physician's office handy to access
                                  your medical records.
                                </p>
                              </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center px-5">
                          <button
                            onClick={() =>
                              navigateEmr(pt, prospect_city, prospect_dob, prospect_gender)
                            }
                            className="btn btn-small gn_btn my-2 px-3"
                          >
                            Next
                          </button>
                        </div>
                  </div>}
                </div>
              ) : null}
              <div className="d-flex align-items-center justify-content-center">
                {/* <div
                  style={{ textDecoration: "underline", color: "#006D9C" }}
                  className="row back_link_buttons"
                > */}
                  {/* <div
                    
                    className="d-flex align-items-center justify-content-between m-1"
                  > */}
                    <button onClick={() => backToSearch()}
                    style={{ cursor: "pointer", fontSize : 19, letterSpacing: 0.7,
                    textTransform: "uppercase",
                    opacity: 1 }} className="dt_btn mb-0 p-3">
                      Back to the search result
                    </button>
                    {/* <img src={arrowRight} alt={"arrowRight"}></img> */}
                  {/* </div> */}
                {/* </div> */}
              </div>
              {/* <div className="row">
                <div className="ml-2 d-flex  align-items-center justify-content-center">
                  <img
                    className="completed_icon"
                    src={step1}
                    alt={"step1"}
                  ></img>
                  <div className="checkedsteps mt-3 ml-3">
                    <img src={CheckedIcon} alt={"checkedstep1"}></img>
                    <h2 className="my-3">1.Find a Trial</h2>
                  </div>
                </div>
                <div className="ml-2 d-flex  align-items-center justify-content-center ml-5 pl-0">
                  <img
                    className="completed_icon"
                    src={step2}
                    alt={"step1"}
                  ></img>
                  <div className="checkedsteps mt-3 ml-3">
                    <img src={CheckedIcon} alt={"checkedstep2"}></img>
                    <h2 className="my-3">2.Enroll</h2>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="col-sm-12 col-md-12 col-lg-4">
            <PopularSearch />
          </div> */}
        </div>
        {/* next steps section */}
        <div className="nextsteps text-left">
          <h3 className="main_page_title">HOW IT WORKS</h3>
          <p className="main_page_subtitle">What to expect about the next steps</p>
          <div className="row">
            {nextsteps.map((nextstep, i) => (
              <NextStep key={i} {...nextstep} />
            ))}
          </div>
        </div>
      </div>
      {/* download app section */}
      {/* <div className="container-fluid p-0 m-0">
        <div className="row">
          <div className="mt-5 col-sm-12 col-md-12 col-lg-4 text-left download-left p-0 m-0">
            <img className="my-5" src={logo} alt="logo"></img>
            <br></br>
            <h3 className="my-4">Wellness Auto Agent</h3>
            <p className="my-3">
              Download the PatienTrials App to track your progress and get
              immediate support from our health coordinator!
            </p>
            <div className="my-3 row d-flex align-items-start justify-content-center">
              <img src={QR} alt="qrcode"></img>
              <div className="d-flex align-items-center justify-content-end mt-auto">
                <a
                  href="https://play.google.com/store/apps/details?id=com.patientrials.app.patient"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img className="mx-2" src={PlayStore} alt="playstore"></img>
                </a>
                <a
                  href="https://apps.apple.com/us/app/patientrials/id1386748565"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img className="mx-2" src={AppStore} alt="appstore"></img>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-8 download_right p-0 m-0">
            <div className="mt-2 blur">
              <img src={Iphone} alt="phoneIcon"></img>
            </div>
          </div>
        </div>
      </div> */}
      <div style={{background: '#034EA2 0% 0% no-repeat padding-box',opacity: 1}} className="container-fluid px-0 mx-0">
         <div className="container px-1">
            <div className="row">
                <div className="col-lg-6 text-left pt-5">
                    <h6 style={{fontStyle: "normal", fontWeight: 'bold', fontSize :  22 , fontFamily :  'NunitoRegular',letterSpacing: 0,color: "#FFFFFF",opacity: 1}} className="pt-5">Wellness Auto Agent</h6>
                    <p style={{fontStyle: "normal", fontWeight: 400, fontSize :  16 , fontFamily :  'NunitoRegular',letterSpacing: 0,color: "#FFFFFF",opacity: 1}} className="mb-0">Download the PatienTrials App to track your progress and get immediate support from our health coordinator!</p>
                </div>
                <div className="col-lg-6 text-right">
                    <img src={i_pt_phone} alt="phone"></img>
                </div>
            </div>
         </div>
      </div>
    </div>
  );
};

const ThankYou = (props) => {
  const { hideSteps, studyId } = props;
  const clearCriteria = localStorage.removeItem("criteria");
  const clearCurrentPage = localStorage.removeItem("currentPage");
  const { pt } = useParams();
  log(props.location.search , 'params');
  let search = props && props.location && props.location.search;
  
  //log(`${clearCriteria},${clearCurrentPage},'clear'`);

  let prospect_city = window.localStorage.getItem("city");
  let prospect_dob = window.localStorage.getItem("dob");
  let prospect_gender = window.localStorage.getItem("gender");

  const [showNextStep, setShowNextStep] = useState(true);
  const [ preconnect , setPreconnect] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if(search !== undefined){
      log(search, 'search')
      if(search && search.length > 0){
        setPreconnect(true)
      }
    }
  }, []);

  return (
    <div>
      {/* <ChatScript /> */}
      {hideSteps === true ? null : <RecruitmentSteps step={"Enroll"} />}
      <RenderNextSteps
        {...props}
        pt={pt}
        prospect_city={prospect_city}
        prospect_dob={prospect_dob}
        prospect_gender={prospect_gender}
        nextStep={showNextStep}
        setShowNextStep={setShowNextStep}
        preconnect = {preconnect}
      />
    </div>
  );
};
export default ThankYou;
